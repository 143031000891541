export default {
    'optimization': {
        'platform_specific': {
            'facebook': {
                'objective': 'OUTCOME_AWARENESS',
                'optimization_goal': {
                    'ui_name': 'Reach',
                    'platform_value': 'REACH'
                },
                'billing_event': {
                    'ui_name': 'Impressions',
                    'platform_value': 'IMPRESSIONS'
                },
            },
            'snapchat': {
                'objective': 'BRAND_AWARENESS',
                'optimization_goal': {
                    'ui_name': 'Impressions',
                    'platform_value': 'IMPRESSIONS'
                },
                'billing_event': {
                    'ui_name': 'Impressions',
                    'platform_value': 'IMPRESSIONS'
                },
            },
            'tiktok': {
                'objective': 'REACH',
                'optimization_goal': {
                    'ui_name': 'Reach',
                    'platform_value': 'REACH'
                },
                'billing_event': {
                    'ui_name': 'Cost Per Thousand',
                    'platform_value': 'CPM'
                },
            },
            'pinterest': {
                'objective': 'AWARENESS',
                'optimization_goal': {
                    'ui_name': 'Awareness',
                    'platform_value': 'AWARENESS'
                },
                'billing_event': {
                    'ui_name': 'Impression',
                    'platform_value': 'IMPRESSION'
                },
            }
        }
    },
    'audience': {
        'platform_specific': {
            'facebook': {
                'ages': {
                    'min': {
                        'name': '18',
                        'value': 18
                    },
                    'max': {
                        'name': '65+',
                        'value': 65
                    }
                },
                'gender': 'ALL',
                'existing_audiences': []
            },
            'tiktok': {
                'ages': {
                    'min': {
                        'name': '18',
                        'value': 18
                    },
                    'max': {
                        'name': '55+',
                        'value': 55
                    }
                },
                'gender': 'ALL',
                'existing_audiences': []
            },
            'snapchat': {
                'ages': {
                    'min': {
                        'name': '18',
                        'value': 18
                    },
                    'max': {
                        'name': '50+',
                        'value': 50
                    }
                },
                'gender': 'ALL',
                'existing_audiences': []
            },
            'pinterest': {
                'ages': {
                    'min': {
                        'name': '18',
                        'value': 18
                    },
                    'max': {
                        'name': '65+',
                        'value': 65
                    }
                },
                'gender': 'ALL',
                'existing_audiences': []
            }
        }
    }
};
