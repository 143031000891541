<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="true"
        :step="name"
        :title="$t('adDeployment.presetSelection.title')"
        :help-link="helpLink"
        :description="$t('adDeployment.presetSelection.description')">
        <complete v-if="!active" />
        <div
            v-else
            class="preset-container">
            <div class="inside-container">
                <div
                    v-for="(panel, index) in panels"
                    :key="index"
                    class="panel-container"
                    :class="{
                        selected: panel.selected,
                    }"
                    @click="selectsPreset(index)">
                    <icon
                        class="mb-2 mt-2"
                        size="34"
                        :name="panel.icon" />
                    <h2>{{ panel.title }}</h2>
                    <p>{{ panel.description }}</p>
                    <styled-checkbox
                        :checked="selectedPreset?.title === panel.title"
                        @change="selectsPreset(index)" />
                </div>
            </div>
            <styled-button
                v-if="active"
                class="mt-3"
                small
                :disabled="!selectedPreset"
                @click="setsConfiguration">
                Continue
            </styled-button>
        </div>
    </step-wrapper>
</template>

<script>
import StepWrapper from '../../components/StepWrapper';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import StyledButton from '@/components/globals/StyledButton';
import Icon from '@/components/globals/Icon';
import stepProps from '../stepProps';
import { mapActions } from 'vuex';
import reachPreset from './defaults/reach';
import trafficPreset from './defaults/traffic';
import Complete from './Complete.vue';

export default {
    name: 'Presets',
    components: {
        StepWrapper,
        StyledCheckbox,
        StyledButton,
        Icon,
        Complete
    },
    props: stepProps,
    data() {
        return {
            helpLink:
                'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#preset-choose-objective',
            panels: [
                {
                    title: this.$t('adDeployment.presetSelection.reach.title'),
                    description: this.$t(
                        'adDeployment.presetSelection.reach.description'
                    ),
                    preset: reachPreset,
                    icon: 'star-person-icon',
                    selected: false,
                },
                {
                    title: this.$t('adDeployment.presetSelection.traffic.title'),
                    description: this.$t(
                        'adDeployment.presetSelection.traffic.description'
                    ),
                    preset: trafficPreset,
                    icon: 'hiarchy-people-icon',
                    selected: false,
                },
                {
                    title: this.$t('adDeployment.presetSelection.custom.title'),
                    description: this.$t(
                        'adDeployment.presetSelection.custom.description'
                    ),
                    preset: {},
                    icon: 'gear-wrench-icon',
                    selected: false,
                },
            ],
        };
    },
    computed: {
        adDeployment: {
            get() {
                return this.$store.state.adDeployment;
            },
            set(preset) {
                this.$store.commit('adDeployment/updateAdDeploymentPreset', { preset });
            }
        },
        selectedPreset: {
            get() {
                return this.$store.state.adDeployment.selectedPreset;
            },
            set(preset) {
                this.$store.commit('adDeployment/updateSelectedPreset', { preset });
            }
        }
    },
    created() {
        if (!this.selectedPreset && this.adDeployment.current_step > 0) {
            const customPreset = this.panels.find(panel => panel.title === 'Custom');
            this.selectedPreset = customPreset;
        }
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
        selectsPreset(index) {
            this.panels = this.panels.map((panel, i) => {
                panel.selected = i === index;

                if (panel.selected) {
                    this.selectedPreset = panel;
                }

                return panel;
            });
        },
        setsConfiguration() {
            if (this.selectedPreset) {
                this.adDeployment = this.selectedPreset.preset;
                this.goToNextStep();
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.preset-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .inside-container {
        display: flex;
        flex-direction: row;

        .panel-container {
            background-color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 7px;
            text-align: center;
            width: 240px;
            height: 250px;
            margin-right: 15px;

            p {
                color: #757575;
            }

            &:hover,
            &.selected {
                cursor: pointer;
                border: 1px #51c1f7 solid;
            }
        }
    }

    button {
        width: 104px;
    }
}
</style>
