<template>
    <div class="preset-wrapper">
        <div class="completed">
            <div class="white-box-wrapper">
                <div class="preset-title">
                    PROFILE <span class="ml-3 link">{{ selectedPreset.title }} </span>
                </div>
            </div>
            <a
                href="#"
                class="sad-edit"
                @click.prevent="handleEdit">
                edit
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            selectedPreset: state => state.adDeployment.selectedPreset
        }),
    },
    methods: {
        handleEdit() {
            this.$store.dispatch(
                'adDeployment/goToStepByName',
                'presets_selection'
            );
        },
    }
};
</script>


<style lang="scss" scoped>
.preset-wrapper {
    display: flex;
    flex-direction: column;

    .completed {
        display: flex;
        flex-direction: row;
        align-items: center;

        .white-box-wrapper {
            background: white;
            border: 1px solid #DFDFE0;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            padding: 15px;

            .preset-title {
                font-weight: 700;

                .link {
                    color: #03A2EA;
                    font-style: italic;
                    font-weight: 500;
                    display: inline-block;
                }
            }
        }
    }
}
</style>