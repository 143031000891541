export default {
    'optimization': {
        'platform_specific': {
            'facebook': {
                'objective': 'OUTCOME_TRAFFIC',
                'optimization_goal': {
                    'ui_name': 'Link Clicks',
                    'platform_value': 'LINK_CLICKS'
                },
                'billing_event': {
                    'ui_name': 'Link Clicks',
                    'platform_value': 'LINK_CLICKS'
                },
            },
            'snapchat': {
                'objective': 'WEB_VIEW',
                'optimization_goal': {
                    'ui_name': 'Pixel Page View',
                    'platform_value': 'PIXEL_PAGE_VIEW'
                },
                'billing_event': {
                    'ui_name': 'Pixel Page View',
                    'platform_value': 'PIXEL_PAGE_VIEW'
                },
            },
            'tiktok': {
                'objective': 'TRAFFIC',
                'optimization_goal': {
                    'ui_name': 'Click',
                    'platform_value': 'CLICK'
                },
                'billing_event': {
                    'ui_name': 'Cost Per Click',
                    'platform_value': 'CPC'
                },
            },
            'pinterest': {
                'objective': 'CONSIDERATION',
                'optimization_goal': {
                    'ui_name': 'Click Through',
                    'platform_value': 'CLICKTHROUGH'
                },
                'billing_event': {
                    'ui_name': 'Click Through',
                    'platform_value': 'CLICKTHROUGH'
                },
            }
        }
    },
    'audience': {
        'platform_specific': {
            'facebook': {
                'ages': {
                    'min': {
                        'name':'18',
                        'value':18
                    },
                    'max': {
                        'name':'65+',
                        'value':65
                    }
                },
                'gender':'ALL',
                'existing_audiences':[]
            },
            'tiktok': {
                'ages': {
                    'min': {
                        'name':'18',
                        'value':18
                    },
                    'max': {
                        'name':'55+',
                        'value':55
                    }
                },
                'gender':'ALL',
                'existing_audiences':[]
            },
            'snapchat': {
                'ages': {
                    'min':{
                        'name':'18',
                        'value':18
                    },
                    'max':{
                        'name':'50+',
                        'value':50
                    }
                },
                'gender':'ALL',
                'existing_audiences':[]
            },
            'pinterest': {
                'ages': {
                    'min': {
                        'name':'18',
                        'value':18
                    },
                    'max': {
                        'name':'65+',
                        'value':65
                    }
                },
                'gender':'ALL',
                'existing_audiences':[]
            }
        }
    }
};